import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import tw from 'twin.macro'

import Layout from '../../components/Layout'
import SEO from '../../components/Seo'
import { H1, H2, H3 } from '../../components/Headings'
import Container from '../../components/Container'

const PageTemplate = ({ data }) => {
  const about = data.allWpPage.edges[0].node.about
  const {
    missionHeadline,
    missionSubheading,
    missionParagraph,
    missionImages,
    positionHeadline,
    positionParagraph,
    positionBlocks,
    integrityHeadline,
    integrityParagraph,
    integrityBlocks,
  } = about

  return (
    <>
      <SEO />
      <Layout>
        <section aria-label="Our Mission">
          <Container tw="py-16 md:(grid grid-cols-5 col-gap-8)">
            <div tw="col-span-3 flex flex-col justify-center mb-8 md:(mb-0)">
              <H2 tw="text-5xl">{missionHeadline}</H2>
              <p tw="text-gray-700 italic text-xl mb-4">{missionSubheading}</p>
              <p tw="text-xl leading-relaxed">{missionParagraph}</p>
            </div>
            <div tw="col-span-2 grid grid-cols-2 col-gap-4 row-gap-4">
              {missionImages.map(({ image, colspan }) => (
                <Img
                  fluid={image.localFile.childImageSharp.fluid}
                  css={[colspan && `grid-column: 1 / span ${colspan || 1};`]}
                />
              ))}
            </div>
          </Container>
        </section>
        <section tw="bg-gray-50" aria-label="Our Position">
          <Container tw="py-16 text-center">
            <H2>{positionHeadline}</H2>
            <p tw="leading-relaxed mb-8 max-w-xl mx-auto text-gray-600">
              {positionParagraph}
            </p>
            <div tw="md:(grid grid-cols-3 col-gap-8 row-gap-8)">
              {positionBlocks.map(({ image, title, paragraph }) => (
                <div tw="text-center mb-8 md:(mb-0 text-left)">
                  <Img
                    fixed={image.localFile.childImageSharp.fixed}
                    tw="mb-4 max-w-full"
                  />
                  <H3 tw="text-2xl mb-4">{title}</H3>
                  <p tw="text-gray-600 leading-relaxed">{paragraph}</p>
                </div>
              ))}
            </div>
          </Container>
        </section>
        <section aria-label="Culture of Integrity">
          <Container tw="pt-16 md:(py-16) text-center">
            <H2>{integrityHeadline}</H2>
            <p tw="text-gray-700 leading-relaxed mb-8 max-w-3xl mx-auto text-gray-600">
              {integrityParagraph}
            </p>
            <div tw="md:(grid grid-cols-3 col-gap-8 row-gap-8)">
              {integrityBlocks?.map(({ icon, title, paragraph }) => (
                <div tw="mb-8 md:(mb-0) flex flex-col items-center">
                  <div tw="w-32 flex items-center justify-center h-32 border-4 rounded-full border-yellow-600 mb-4">
                    <Img
                      fixed={icon.localFile.childImageSharp.fixed}
                      tw="h-24"
                    />
                  </div>
                  <H3 tw="text-2xl">{title}</H3>
                  <p tw="text-gray-600 leading-relaxed">{paragraph}</p>
                </div>
              ))}
            </div>
          </Container>
        </section>
      </Layout>
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          about {
            missionHeadline
            missionSubheading
            missionParagraph
            missionImages {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              colspan
            }
            positionHeadline
            positionParagraph
            positionBlocks {
              image {
                localFile {
                  childImageSharp {
                    fixed(height: 800) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
              title
              paragraph
            }
            integrityHeadline
            integrityParagraph
            integrityBlocks {
              icon {
                localFile {
                  childImageSharp {
                    fixed(height: 800) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
              title
              paragraph
            }
          }
        }
      }
    }
  }
`
